import { OVField, OVForm, OVSidebar, OVTable, OVEntity, OVSearchable, CompiledFieldData, FieldParamsQuery } from '@ov-suite/ov-metadata';
import { CustomerGroup, Domain, Transporter } from '@ov-suite/models-admin';
import { UserType } from './user-type.model';

@OVEntity('UserStatus', 'idmlink')
export class UserStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('User', 'idmlink')
@OVForm([
  ['firstName', 'lastName'],
  ['email', 'phoneNumber'],
  ['cognitoUser'],
  ['domains'],
  ['userTypes'],
  ['customerGroups'],
  ['transporters'],
])
@OVSidebar([['avatarUrl'], ['status'], ['id']])
@OVTable<User>([
  {
    key: 'id',
    title: 'User ID',
    type: 'string',
  },
  {
    key: 'firstName',
    title: 'User Name',
    type: 'string',
  },
  {
    key: 'lastName',
    title: 'User Surname',
    type: 'string',
  },
  {
    key: 'email',
    title: 'Email Address',
    type: 'string',
  },
  {
    key: 'phoneNumber',
    title: 'Cell Number',
    type: 'string',
  },
  {
    key: 'cognitoUser',
    title: 'Can Login',
    disableFiltering: true,
    type: 'boolean',
  },
  {
    type: 'other',
    title: 'User Types',
    action: item => item?.userTypes?.map(d => d.name).join(', ') || 'None',
    keys: ['userTypes.name'],
  },
  {
    type: 'other',
    title: 'Domains',
    action: item => item?.domains?.map(d => d.name).join(', ') || 'None',
    keys: ['domains.name', 'domains.domainId'],
  },
])
export class User {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Mobile',
    required: (data, dataQuery: FieldParamsQuery) => dataQuery('cognitoUser') === true,
    placeholder: '+27000000000',
    tooltip: 'Add users\'s cell phone number',
    validator: (data: CompiledFieldData<string>, dataQuery: FieldParamsQuery): [boolean, string] => {
      const cognitoUser = dataQuery('cognitoUser') === true;

      if (!cognitoUser) {
        return [true, ''];
      }

      return [!!data?.value?.includes('+27'), 'Phone number must Start with +27'];
    },
  })
  phoneNumber: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'First Name',
    required: true,
    placeholder: 'Required',
    tooltip: 'Add users\'s first name',
  })
  firstName: string;

  @OVField({
    type: 'string',
    title: 'Last Name',
    required: true,
    placeholder: 'Required',
    tooltip: 'Add users\'s surname',
  })
  lastName: string;

  @OVField({
    type: 'boolean',
    title: 'Can Login',
    placeholder: 'Required',
    tooltip: 'User that can login',
  })
  cognitoUser?: boolean = true;

  @OVField({
    type: 'string',
    title: 'Email Address',
    required: (data, dataQuery: FieldParamsQuery) => dataQuery('cognitoUser') === true,
    placeholder: 'mail@example.com',
    tooltip: 'Add users\'s email address',
  })
  email: string;

  @OVField({
    type: () => [Domain],
    tooltip: 'Add multiple domains where necessary.',
    keys: ['id', 'name'],
    title: 'Domains',
    selectionType: 'multiple',
    flat: true,
    required: false,
  })
  domains: Domain[];

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip: 'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
    required: true,
  })
  userTypes: UserType[];

  @OVField({
    type: () => [CustomerGroup],
    keys: ['id', 'name'],
    title: 'Customer Groups',
    tooltip: 'Add multiple customers where necessary.',
    selectionType: 'multiple',
    flat: true,
    required: false,
    dropdownLimit: 100000,
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: () => [Transporter],
    keys: ['id', 'name'],
    title: 'Transporters',
    tooltip: 'Add multiple Transporters where necessary.',
    selectionType: 'multiple',
    flat: true,
    required: false,
  })
  transporters: Transporter[];

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
    tooltip: 'upload your profile picture',
  })
  avatarUrl?: string;

  @OVField({
    type: () => UserStatus,
    dropdown: true,
    sidebar: true,
  })
  status: UserStatus;

  @OVField({
    type: 'boolean',
    tooltip: 'Prefer sms.',
    title: 'Notify via sms',
    required: true,
  })
  smsNotification: boolean;

  @OVField({
    type: 'boolean',
    tooltip: 'Prefer email.',
    title: 'Notify via email',
    required: true,
  })
  emailNotification: boolean;

  @OVField({
    type: 'time',
    tooltip: 'Notification Window Start Time',
    title: 'Notification Window Start',
    required: true,
  })
  notificationWindowStart: string;

  @OVField({
    type: 'time',
    tooltip: 'Notification Window End Time',
    title: 'Notification Window End',
    required: true,
  })
  notificationWindowEnd: string;
}
