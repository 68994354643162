import { OVField, OVEntity, OVSearchable, CompiledFieldData, FieldParamsQuery } from '@ov-suite/ov-metadata';
import { Domain } from '@ov-suite/models-admin';
import { DomainRepEntity } from '@ov-suite/modules-api/admin/domain';
import { UserStatus } from './user.model';
import { UserType } from './user-type.model';

@OVEntity('User', 'shared')
export class UserRep {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Mobile',
    required: (data, dataQuery: FieldParamsQuery) => dataQuery('cognitoUser') === true,
    placeholder: '+27000000000',
    tooltip: 'Add users\'s cell phone number',
    validator: (data: CompiledFieldData<string>, dataQuery: FieldParamsQuery): [boolean, string] => {
      const cognitoUser = dataQuery('cognitoUser') === true;

      if (!cognitoUser) {
        return [true, ''];
      }

      return [!!data?.value?.includes('+27'), 'Phone number must Start with +27'];
    },
  })
  phoneNumber: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'First Name',
    required: true,
    placeholder: 'Required',
    tooltip: 'Add users\'s first name',
  })
  firstName: string;

  @OVField({
    type: 'string',
    title: 'Last Name',
    required: true,
    placeholder: 'Required',
    tooltip: 'Add users\'s surname',
  })
  lastName: string;

  @OVField({
    type: 'boolean',
    title: 'Can Login',
    placeholder: 'Required',
    tooltip: 'User that can login',
  })
  cognitoUser?: boolean = true;

  @OVField({
    type: 'string',
    title: 'Email Address',
    required: (data, dataQuery: FieldParamsQuery) => dataQuery('cognitoUser') === true,
    placeholder: 'mail@example.com',
    tooltip: 'Add users\'s email address',
  })
  email: string;

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip: 'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
    required: true,
  })
  userTypes: UserType[];

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
    tooltip: 'upload your profile picture',
  })
  avatarUrl?: string;

  @OVField({
    type: () => UserStatus,
    dropdown: true,
    sidebar: true,
  })
  status: UserStatus;
}
