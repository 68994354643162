import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  envName: 'production',
  cognito: {
    region: 'eu-west-1',
    clientId: '56lsdjcgqce32t3na2tt647sqq',
    identityPoolId: 'eu-west-1:038a3773-c31d-4fa2-8261-1d8f240ffd2f',
    userPoolId: 'eu-west-1_3U1UH5apB',
    bucketId: 'ovsuitebucketdevelop-develop',
    oauth: {},
    passwordPolicyText:
      'Password must contain at least 8 characters, 1 upper case, 1 lower case character, a number and 1 special character',
  },
  apiUrl: {
    idm: 'https://uat.api.account.clovertransport.co.za',
    admin: 'https://uat.api.admin.clovertransport.co.za',
    warehouse: 'https://uat.api.warehouse.clovertransport.co.za',
    yard: 'https://uat.api.yard.clovertransport.co.za',
    yardWs: 'wss://uat.api.yard.clovertransport.co.za',
    execution: 'https://uat.api.execution.clovertransport.co.za',
    ceramicPortal: 'https://uat.api.ceramic-portal.clovertransport.co.za',
    order: 'https://uat.api.order.clovertransport.co.za',
  },
  webUrl: {
    idm: 'https://uat.account.clovertransport.co.za',
    admin: 'https://uat.admin.clovertransport.co.za',
    warehouse: 'https://uat.warehouse.clovertransport.co.za',
    yard: 'https://uat.yard.clovertransport.co.za',
    execution: 'https://uat.execution.clovertransport.co.za',
    ceramicPortal: 'https://uat.ceramic-portal.clovertransport.co.za',
    order: 'https://uat.order.clovertransport.co.za',
  },
  appId: {
    idm: 8,
    admin: 9,
    warehouse: 1,
    yard: 6,
    execution: 5,
    ceramicPortal: 7,
    order: 3,
  },
  other: {
    mapApiKey: 'AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs',
    awsFileServiceApi: 'https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev',
  },
  inhanceWorker: {
    authUrl: 'https://login.clover.inhancesc.com/oauth2/token',
    appClientId: '70g9l4b1fhh16qjs8f3snpe6of',
    appClientSecret: 'lv4p7m4ap1mbcievvav41jujoefh8dr24ukqc7u0j5dm7hfano9',
    apiUrl: 'https://ovq15csbyc.execute-api.eu-west-1.amazonaws.com/Prod/opsi/yard/',
  },
  redis: {

  },
};
